<template>
  <div class="bg">
    <div class="cen">
      <div class="text-center">
        <img
          src="/logo/LToTHAI.png"
          alt="logo-lotto"
          height="60px"
          style="margin-top: 200px;"
        >
      <!-- button router-link to="/Lottery" -->
      </div>

      <div class="text-center mt-4">
        <button
          class="btn btn-warning btn-lg"
          style="background: linear-gradient(184.78deg, #EFD47A -5.17%, #C68C31 49.71%, #FFF4BD 96.14%); color: #000 !important; font-weight: 700;"
          @click="LinkToLine()"
        >
          ซื้อหวย คลิก
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invcode: this.$route.params.invcode,
    }
  },
  mounted() {
    if (this.$route.params.invcode) {
      this.invcode = this.$route.params.invcode
    }
  },
  methods: {
    LinkToLine() {
      window.location.href = 'https://line.me/R/ti/p/@149lpsre'
    },
  },
}
</script>

<style scoped>
.bg {
  background-color: #fff;
  height: 100vh;
}
.bg {
  /* background-image: url(/logo/bg3.jpg) ; */
  background-image: url('/bgLotto.svg') !important;
  background-attachment: fixed !important;
}
img {
    width: auto;
    height: 150px;
}
</style>
